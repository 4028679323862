body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #192155;
  font-family:  Outfit, sans-serif;
}
.form {
  background-image: linear-gradient(180deg, #78018B 0%, #5358DE 100%);
  padding: 30px 24px;
  border-radius: 10px;
  font-family:  Outfit, sans-serif;
}

@media (max-width: 768px) {
  .form {
    border-radius: 0px;
  }
  .container {
    padding: 0 !important;
    height: 100vh;
  }
  header {
    padding: 0 8px;
  }
}

.form h2 {
  color: white;
    font-family:  Outfit, sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.normal-text {
  color: white;
  font-family:  Outfit, sans-serif;
  font-size: 16px;
  text-align: center;
}

.form label {
  color: white;
}

.form .btn {
  background-color: rgb(0,255,255);
  justify-self: center;
  color: black;
  font-weight: bold;
  border-radius: 3px;
  padding-left: 30px;
  padding-right:30px;
}

.form .btn:hover {
  background-color: black;
  color: rgb(0,255,255);;
}